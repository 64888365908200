//frontend\src\services\api\reviewApi.js

import { axiosInstance, handleError, fetchWithCache, clearRelatedCache } from '../apiService';

export const getUserReviews = async () => {
    try {
      const response = await axiosInstance.get('/reviews/get-user-reviews');
      return response.data;
    } catch (error) {
      console.error('Error fetching user reviews:', error);
      throw error;
    }
};
  
export const getVideoReviewCount = async (videoId) => {
  const reviews = await getVideoReviews(videoId);
  return reviews.length;
};
  
export const getVideoReviews = async (videoId) => {
  return fetchWithCache(`/reviews/get-video-reviews/${videoId}`);
};
  
export const batchGetVideoReviewCounts = async (videoIds) => {
  const promises = videoIds.map(id => getVideoReviewCount(id));
  const counts = await Promise.all(promises);
  return videoIds.reduce((acc, id, index) => {
    acc[id] = counts[index];
    return acc;
  }, {});
};

export const submitReview = async (videoId, rating, content) => {
  try {
    const response = await axiosInstance.post(`/reviews/add-review/${videoId}`, { rating, content });
    clearRelatedCache('/reviews', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error submitting review:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteReview = async (reviewId) => {
  try {
    const response = await axiosInstance.delete(`/reviews/delete-review/${reviewId}`);
    clearRelatedCache('/reviews', 'DELETE');
    return response.data;
  } catch (error) {
    console.error('Error deleting review:', error);
    throw error;
  }
};

export const likeReview = async (reviewId) => {
  try {
    const response = await axiosInstance.post(`/reviews/like-review/${reviewId}`);
    clearRelatedCache('/reviews', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error liking review:', error);
    throw error;
  }
};

export const unlikeReview = async (reviewId) => {
  try {
    const response = await axiosInstance.post(`/reviews/unlike-review/${reviewId}`);
    clearRelatedCache('/reviews', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error unliking review:', error);
    throw error;
  }
};

export const getAllReviews = async() => {
  try {
      const response = await axiosInstance.get('/reviews/get-reviews');
      return response.data;
  } catch (error) {
      console.error('Error fetching admin reviews:', error);
      throw error;
  }
};