import React, { useState } from 'react';
import { searchYoutube, batchValidateAndCheck, validateYoutubeUrl } from '../services/apiService';
import { rawDurationToHMS, formatViewCount} from '../utils/formatters';

function YoutubeSearch({ onVideoSelect, onQuickAdd, activeTab, selectedVideos }) {
  const [input, setInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const isYoutubeLink = (url) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  };

  const handleVideoSelect = (video) => {
    const videoId = video.id || video.videoId;
    onVideoSelect(video);
  };


  const handleSearch = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null);
      setHasSearched(true);

    try {
      if (isYoutubeLink(input)) {
        const videoInfo = await validateYoutubeUrl(input);
        setSearchResults([{
          ...videoInfo,
          exists: false,
          status: 'valid'
        }]);
      } else {
        const results = await searchYoutube(input);
        const validatedResults = await batchValidateAndCheck(results.map(video => video.id));
        
        const updatedResults = results.map(video => {
          const validatedVideo = validatedResults.find(v => v.videoId === video.id);
          return {
            ...video,
            ...validatedVideo,
            exists: validatedVideo ? validatedVideo.status === 'invalid' : false,
            status: validatedVideo ? validatedVideo.status : 'unknown'
          };
        });
        
        setSearchResults(updatedResults);
      }
    } catch (err) {
      console.error('Error in search process:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <div className="youtube-search">
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter YouTube URL or search term"
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </form>

      {error && <p className="error">{error}</p>}

      {hasSearched && (
      <table className="search-results-table">
        <thead>
          <tr>
            <th className="thumbnail-col">Thumbnail</th>
            <th className="title-col">Title</th>
            <th className="channel-col">Channel</th>
            <th className="views-col">Views</th>
            <th className="duration-col">Duration</th>
            <th className="action-col">Action</th>
          </tr>
        </thead>
        <tbody>
        {searchResults.map((video) => (
        <tr 
        key={video.id || video.videoId} 
        className={selectedVideos.includes(video.id || video.videoId) ? 'selected-row' : ''}
        >
            <td className = "search-thumbnail">
              <img src={video.thumbnailUrl} alt={video.title} style={{width: '120px'}} />
            </td>
            <td className = "search-title">
              <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer">
                {video.title}
              </a>
            </td>
            <td>{video.channelTitle}</td>
            <td>{formatViewCount(video.viewCount)}</td>
            <td className = "search-duration">{rawDurationToHMS(video.duration)}</td>
            <td>
              {!video.exists && (
                <>
                  <button onClick={() => handleVideoSelect(video)}>
                    {selectedVideos.includes(video.id || video.videoId) ? 'Selected' : 'Select'}
                  </button>
                  {activeTab === 'single' && <button onClick={() => onQuickAdd(video)}>QuickAdd</button>}
                </>
              )}
              {video.exists && (
                <button disabled>Already Added</button>
              )}
            </td>
        </tr>
      ))}
    </tbody>
    </table>
    )}
    </div>
  );
};

export default YoutubeSearch;