// src/services/cacheService.js

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

class CacheService {
  constructor() {
    this.cache = new Map();
  }

  set(key, value, duration = CACHE_DURATION) {
    this.cache.set(key, {
      value,
      timestamp: Date.now(),
      duration
    });
  }

  get(key) {
    const cached = this.cache.get(key);
    if (!cached) return null;

    const isExpired = Date.now() - cached.timestamp > cached.duration;
    if (isExpired) {
      this.cache.delete(key);
      return null;
    }

    return cached.value;
  }

  clear() {
    this.cache.clear();
  }

  forceRefresh(key) {
    this.cache.delete(key);
  }
}

export default new CacheService();