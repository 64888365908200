// src/utils/auth.js

import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; 

const API_URL = process.env.REACT_APP_API_URL;

let refreshTokenTimeout;

export const setToken = (token) => {
  localStorage.setItem('token', token);
  const decoded = jwtDecode(token);
  const expiresIn = (decoded.exp * 1000) - Date.now() - (60 * 1000);
  refreshTokenTimeout = setTimeout(refreshToken, expiresIn);
};

export const getToken = () => {
  const token = localStorage.getItem('token');
  return token;
};

export const removeToken = () => {
  localStorage.removeItem('token');
  if (refreshTokenTimeout) clearTimeout(refreshTokenTimeout);
};

export const setRefreshToken = (token) => {
  // Store refresh token in an HTTP-only cookie instead of localStorage
  document.cookie = `refreshToken=${token}; path=/; secure; HttpOnly; SameSite=Strict`;
};

export const getRefreshToken = () => {
  // Retrieve refresh token from cookie
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('refreshToken='));
  return cookieValue ? cookieValue.split('=')[1] : null;
};

export const removeRefreshToken = () => {
  // Remove refresh token cookie
  document.cookie = 'refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; HttpOnly; SameSite=Strict';
};

export const loginUser = async (email, password, rememberMe) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, { email, password, rememberMe });
    const { token, refreshToken, user } = response.data;
    setToken(token);
    setRefreshToken(refreshToken);
    return user;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logoutUser = () => {
  removeToken();
  removeRefreshToken();
  window.location.href = '/';
};

export const refreshToken = async () => {
  try {
    console.log('Attempting to refresh token');
    const refreshToken = getRefreshToken();
    const response = await axios.post(`${API_URL}/auth/refresh-token`, { refreshToken });
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    setToken(accessToken);
    setRefreshToken(newRefreshToken);
    console.log('Token refreshed successfully');
    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    removeToken();
    removeRefreshToken();
    window.location.href = '/';
  }
};

export const isLoggedIn = () => {
  const token = getToken();
  if (!token) {
    console.log('No token found, user is not logged in');
    return false;
  }
  
  try {
    const decoded = jwtDecode(token);
    const isValid = decoded.exp > Date.now() / 1000;
    console.log(`Token validity checked. Is valid: ${isValid}`);
    return isValid;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};