import { axiosInstance, handleError, fetchWithCache, clearRelatedCache } from '../apiService';

import { setToken, getToken } from '../../utils/auth';

export const getUsers = async() => {
  return fetchWithCache(`/admin//get-users`);
};

export const deleteUser = async(userId) => {
  try {
    const response = await axiosInstance.delete(`/admin/delete-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const getTrustScoreRanking = async() => {
  return fetchWithCache(`/admin//get-users-trust-rankings`);
};



export const triggerJob = async (job) => {
  try {
    const response = await axiosInstance.post('/admin/trigger-job', {job});

    if (response.data && response.data.success) {
      console.log('Job triggered successfully:', response.data.message);
      return response.data;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error triggering job:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

export const getApiStats = async () => {
  try {
    const response = await axiosInstance.get(`/admin/api-stats`);
    
    // Axios response object contains the actual data in the `data` property
    const data = response.data;

    // console.log('Received data:', data);  // Debugging line to inspect the fetched data
    return data;
  } catch (error) {
    console.error('Error fetching /admin/api-stats:', error);
    return null;  // Handle error case
  }
};





export const getApiLogs = async (page = 1, limit = 100) => {
  return fetchWithCache(`/admin//api-logs?page=${page}&limit=${limit}`);
};















export const populateDatabase = async () => {
  console.log('Starting database population...');

  const categories = [
    { name: "Animation", description: "Content featuring animated visuals, including 2D, 3D, and stop-motion." },
    { name: "Film", description: "Movies, short films, and cinematic productions across various genres." },
    { name: "Storytelling", description: "Narrative content, including lore, explanations, and story-driven videos." },
    { name: "Comedy", description: "Humorous content designed to entertain and amuse." },
    { name: "Educational", description: "Content focused on teaching, explaining, and providing knowledge." },
    { name: "Documentary", description: "Non-fiction content that documents real-life events, stories, or subjects." },
    { name: "Science & Technology", description: "Content exploring scientific topics, innovations, and technological advancements." },
    { name: "History", description: "Content related to historical events, figures, and analysis of the past." },
    { name: "Gaming", description: "Content focused on video games, including gameplay, reviews, and walkthroughs." },
    { name: "Lifestyle", description: "Content related to everyday life, personal development, and cultural trends." },
    { name: "Reviews", description: "Critiques and evaluations of products, media, and other items." },
    { name: "Interviews", description: "Conversations and discussions with individuals across various fields." },
    { name: "Music", description: "Content related to music, including performances, videos, and analysis." },
    { name: "Sports", description: "Content covering sports events, analysis, and athletic activities." },
    { name: "Art & Design", description: "Content focused on visual arts, design, and creative expression." },
    { name: "Travel & Adventure", description: "Content related to travel experiences, exploration, and adventure." },
    { name: "News & Current Events", description: "Content covering breaking news, current events, and analysis." },
    { name: "Health & Fitness", description: "Content related to physical health, mental well-being, and fitness." }
  ];
  

  // const subcategories = [
  //   { name: "321", description: "321" },
  //   { name: "4321", description: "4321" },
  //   { name: "54321", description: "54321" },
  // ];

  const tags = [
    "Entertainment", "Education", "Inspiration", "Analysis", "Exploration", "Relaxing", "Sleep", "Background",
    "VR", "3D", "AIGenerated", "Animated", "Film",
    "SciFi", "Drama", "Fantasy", "Horror", "Satire", "Documentary", "Action", "Adventure", "Comedy", "Silent",
    "Mystery", "Thriller", "Romance", "Crime", "Western", "War", "Historical", "Biographical", "Epic", "Political",
    "Science", "History", "Biology", "Chemistry", "Physics", "Mathematics", "Engineering", "ComputerScience", "Technology", "Agriculture",
    "Entertaining", "Inspiring", "Mysterious", "Surreal",
    "Fanmade", "Improv", "ShortFilm", "TrueCrime"
  ];
  

  const videos = [
    {
      youtubeUrl: "https://www.youtube.com/watch?v=QhethUdHxmU",
      category: "Animation",
      tags: ["123", "1234"],
    },
    {
      youtubeUrl: "https://www.youtube.com/watch?v=TDEgv3mCuas",
      category: "Animation",
      tags: ["123"],
    },
    {
      youtubeUrl: "https://www.youtube.com/watch?v=Cd2aWru1KtM",
      category: "Animation",
      tags: ["123", "1234"],
    },
  ];

  const addCategories = async () => {
    for (const category of categories) {
      try {
        const result = await axiosInstance.post('/categories/create-category', category);
        console.log(`Added category: ${category.name}`);
      } catch (error) {
        console.error(`Error adding category ${category.name}:`, error.message);
      }
    }
  };

  // const addSubcategories = async () => {
  //   for (const subcategory of subcategories) {
  //     try {
  //       const result = await axiosInstance.post('/categories/create-subcategory', subcategory);
  //       console.log(`Added subcategory: ${subcategory.name}`);
  //     } catch (error) {
  //       console.error(`Error adding subcategory ${subcategory.name}:`, error.message);
  //     }
  //   }
  // };

  const addTags = async () => {
    for (const tag of tags) {
      try {
        const result = await axiosInstance.post('/tags/create-tag', { name: tag });
        console.log(`Added tag: ${tag}`);
      } catch (error) {
        console.error(`Error adding tag ${tag}:`, error.message);
      }
    }
  };

  const getCategoryId = async (categoryName) => {
    try {
      const response = await axiosInstance.get(`/categories/get-category-by-name/${categoryName}`);
      return response.data ? response.data._id : null;
    } catch (error) {
      console.error(`Error getting category ID for ${categoryName}:`, error.message);
      return null;
    }
  };

  // const getSubcategoryId = async (subcategoryName) => {
  //   try {
  //     const response = await axiosInstance.get(`/categories/get-subcategory-by-name/${subcategoryName}`);
  //     return response.data ? response.data._id : null;
  //   } catch (error) {
  //     console.error(`Error getting subcategory ID for ${subcategoryName}:`, error.message);
  //     return null;
  //   }
  // };

  const extractVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const addVideos = async () => {
    for (const video of videos) {
      try {
        const extractedId = extractVideoId(video.youtubeUrl);
        console.log(`Processing video with ID: ${extractedId}`);
        
        const validationResponse = await axiosInstance.post('/youtube/batch-validate-and-check-youtube-videos', { videoIds: [extractedId] });
        const validatedVideo = validationResponse.data[0];

        if (!validatedVideo || validatedVideo.status === 'invalid' || validatedVideo.found) {
          console.log(`Video ${video.youtubeUrl} is invalid or already exists, skipping.`);
          continue;
        }

        const categoryId = await getCategoryId(video.category);
        // const subcategoryId = await getSubcategoryId(video.subcategory);

        if (!categoryId 
          // || !subcategoryId
        ) {
          console.log(`Skipping video ${validatedVideo.title} due to missing category or subcategory`);
          continue;
        }

        const videoData = {
          title: validatedVideo.title,
          description: validatedVideo.description,
          videoId: validatedVideo.videoId,
          thumbnailUrl: validatedVideo.thumbnailUrl,
          duration: validatedVideo.duration,
          viewCount: validatedVideo.viewCount,
          likeCount: validatedVideo.likeCount,
          commentCount: validatedVideo.commentCount,
          category: categoryId,
          // subcategory: subcategoryId,
          channelTitle: validatedVideo.channelTitle,
          channelId: validatedVideo.channelId,
          tags: video.tags,
          youtubeCategoryId: validatedVideo.youtubeCategoryId,
          youtubeCategoryName: validatedVideo.youtubeCategoryName,
          youtubeTags: validatedVideo.youtubeTags,
          publishedAt: validatedVideo.publishedAt,
        };

        console.log('Video data to be sent:', videoData);

        const result = await axiosInstance.post('/videos/add-video', videoData);
        console.log(`Added video: ${validatedVideo.title}`);
      } catch (error) {
        console.error(`Error adding video ${video.youtubeUrl}:`, error.message);
      }
    }
  };

  try {
    await addCategories();
    // await addSubcategories();
    await addTags();
    // await addVideos();
    console.log('Database population completed successfully');
    clearRelatedCache();
  } catch (error) {
    console.error('Error populating database:', error.message);
  }
};