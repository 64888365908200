import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../utils/auth';

function UserDropdown({ setShowDropdown }) {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    setShowDropdown(false);
    navigate(path);
  };

  const handleLogout = () => {
    removeToken();
    setShowDropdown(false);
    window.location.reload();
  };

  return (
    <div className="user-dropdown">
      <ul>
        <li>
          <button onClick={() => handleNavigation('/account')}>Account</button>
        </li>
        <li>
          <button onClick={() => handleNavigation('/my-lists')}>My Lists</button>
        </li>
        <li>
          <button onClick={() => handleNavigation('/my-reviews')}>My Reviews</button>
        </li>
        <li>
          <button onClick={handleLogout}>Logout</button>
        </li>
      </ul>
    </div>
  );
}

export default UserDropdown;