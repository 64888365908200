import { axiosInstance, authAxios, fetchWithCache, clearRelatedCache, cacheService } from '../apiService';

export const suggestMetadata = async (videoData) => {
  try {
    const response = await axiosInstance.post('/suggestions/suggest-metadata', { ...videoData });
    return response.data;
  } catch (error) {
    console.error('Error suggesting metadata:', error);
    throw error;
  }
};