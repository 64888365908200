import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';

function DiscoverDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="discover-dropdown" ref={dropdownRef}>
            <button onClick={() => setIsOpen(!isOpen)} className="discover-dropdown-toggle">
                Discover <FiChevronDown />
            </button>
            {isOpen && (
                <ul className="discover-dropdown-menu">
                    <li>
                        <Link to="/categories" onClick={() => setIsOpen(false)}>Browse Categories</Link>
                    </li>
                    <li>
                        <Link to="/lists" onClick={() => setIsOpen(false)}>Browse Lists</Link>
                    </li>
                    <li>
                        <Link to="/tags" onClick={() => setIsOpen(false)}>Browse Tags</Link>
                    </li>
                    <li>
                        <Link to="/search" onClick={() => setIsOpen(false)}>Advanced Search</Link>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default DiscoverDropdown;