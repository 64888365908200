import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import { UserProvider } from './context/UserContext';
import { ListProvider } from './context/ListContext';
import { WatchedVideosProvider } from './context/WatchedVideosContext';
import { DropdownProvider } from './context/DropdownContext';
import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';
import ContactPage from './pages/ContactPage';

import { setToken, getToken } from './utils/auth';
import './styles/base.css';

// Lazy load components
const HomePage = lazy(() => import('./pages/HomePage'));
const VideoPage = lazy(() => import('./pages/VideoPage'));
const SearchResultsPage = lazy(() => import('./pages/SearchResultsPage'));
const CategoryPage = lazy(() => import('./pages/CategoryPage'));
const SubcategoryPage = lazy(() => import('./pages/SubcategoryPage'));
const TagPage = lazy(() => import('./pages/TagPage'));
const ListPage = lazy(() => import('./pages/ListPage'));
const ListBrowsingPage = lazy(() => import('./pages/ListBrowsingPage'));
const UserListsPage = lazy(() => import('./pages/UserListsPage'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const MyReviewsPage = lazy(() => import('./pages/MyReviewsPage'));
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));
const CategoryBrowsingPage = lazy(() => import('./pages/CategoryBrowsingPage'));
const TagBrowsingPage = lazy(() => import('./pages/TagBrowsingPage'));

function App() {
  useEffect(() => {
    // Initialize token refresh on app start
    const token = getToken();
    if (token) {
      setToken(token); // This will set up the refresh timer
    }
  }, []);

  return (
    <UserProvider>
      <ListProvider>
        <WatchedVideosProvider>
          <DropdownProvider>
            <Router>
              <ScrollToTop />
              <Layout>
                <div className="App">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/admin" element={<PrivateRoute adminOnly={true}><AdminPage /></PrivateRoute>} />
                      <Route path="/video/:id" element={<VideoPage />} />
                      <Route path="/search" element={<SearchResultsPage />} />
                      <Route path="/category/:categoryName" element={<CategoryPage />} />
                      <Route path="/subcategory/:subcategoryName" element={<SubcategoryPage />} />
                      <Route path="/tag/:tagName" element={<TagPage />} />
                      <Route path="/list/:id" element={<ListPage />} />
                      <Route path="/lists" element={<ListBrowsingPage />} />
                      <Route path="/account" element={<PrivateRoute><UserProfilePage /></PrivateRoute>} />
                      <Route path="/my-lists" element={<PrivateRoute><UserListsPage /></PrivateRoute>} />
                      <Route path="/my-reviews" element={<PrivateRoute><MyReviewsPage /></PrivateRoute>} />
                      <Route path="/categories" element={<CategoryBrowsingPage />} />
                      <Route path="/tags" element={<TagBrowsingPage />} />
                      <Route path="/contact" element={<ContactPage />} />
                    </Routes>
                  </Suspense>
                </div>
              </Layout>
            </Router>
          </DropdownProvider>
        </WatchedVideosProvider>
      </ListProvider>
    </UserProvider>
  );
}

export default App;