import React, { createContext, useState, useEffect } from 'react';
import { getToken, removeToken, removeRefreshToken } from '../utils/auth';
import { fetchUserData, refreshAuthToken } from '../services/apiService';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initUser = async () => {
      const token = getToken();
      if (token) {
        try {
          const userData = await fetchUserData();
          setUser(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
          if (error.response && error.response.status === 401) {
            try {
              const { accessToken, refreshToken: newRefreshToken } = await refreshAuthToken();
              // Retry fetching user data with new token
              const retryUserData = await fetchUserData();
              setUser(retryUserData);
            } catch (refreshError) {
              console.error('Error refreshing token:', refreshError);
              removeToken();
              removeRefreshToken();
            }
          }
        }
      }
      setLoading(false);
    };

    initUser();
  }, []);

  const updateUser = (userData) => {
    setUser(userData);
  };

  return (
    <UserContext.Provider value={{ user, setUser: updateUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};