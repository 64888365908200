import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

function ReviewSubmissionForm({ onRatingChange, onReviewTextChange, onSubmit, error, initialRating = 0, initialReviewText = '', showSubmitButton = true }) {
  const [rating, setRating] = useState(initialRating);
  const [hover, setHover] = useState(0);
  const [reviewText, setReviewText] = useState(initialReviewText);

  const handleRatingChange = (ratingValue) => {
    setRating(ratingValue);
    onRatingChange(ratingValue);
  };

  const handleReviewTextChange = (e) => {
    const text = e.target.value;
    setReviewText(text);
    onReviewTextChange(text);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(rating, reviewText);
  };

  return (
    <form className="review-form" onSubmit={handleSubmit}>
      <div className="rating-container">
        <div className="rating">
          {[...Array(10)].map((star, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index}>
                <input 
                  type="radio" 
                  name="rating" 
                  value={ratingValue} 
                  onClick={() => handleRatingChange(ratingValue)}
                />
                <FaStar 
                  className="star" 
                  color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                  size={20}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(0)}
                />
              </label>
            );
          })}
        </div>
        <span className="rating-number">{hover || rating || ''}</span>
      </div>
      <textarea
        value={reviewText}
        onChange={handleReviewTextChange}
        placeholder="Write your review here (optional)"
        className="form-control"
      />
      {error && <p className="error-message">{error}</p>}
      {showSubmitButton && (
        <button type="submit" className="submit-review-button">Submit Review</button>
      )}
    </form>
  );
}

export default ReviewSubmissionForm;