//frontend\src\services\api\videoApi.js

import { axiosInstance, authAxios, fetchWithCache, clearRelatedCache, cacheService } from '../apiService';

export const getVideos = async () => {
  return fetchWithCache('/videos//get-all-videos');
};

export const getVideoById = async (id) => {
  return fetchWithCache(`/videos/get-video-by-id/${id}`);
};

// Fetch a group of videos by their IDs, with optional review stats
export const batchGetVideoByIds = async (videoIds, includeReviewStats = false) => {
  try {
    if (!videoIds || videoIds.length === 0) {
      return [];
    }
    
    return await fetchWithCache(`/videos/get-videos-by-ids`, {
      params: { 
        videoIds: JSON.stringify(videoIds),
        includeReviewStats 
      }
    });
  } catch (error) {
    console.error('Error fetching videos by IDs:', error);
    return [];
  }
};

export const addVideo = async (videoData) => {
  try {
    const response = await axiosInstance.post('/videos/add-video', {
      ...videoData,
      // subcategory: videoData.subcategory || null
    });
    //clear all caches
    cacheService.clear();
    console.log('Cache cleared');
    // await axiosInstance.post('/admin/trigger-job', { job: 'updateContentGroupInfo' });

    return response.data;
  } catch (error) {
    console.error('Error adding video:', error);
    throw error;
  }
};

export const checkVideoExists = async (youtubeId) => {
  try {
    const response = await axiosInstance.get(`/videos//check-video-exists/${youtubeId}`);
    if (response.data.found) {
      return response.data.video;
    } else {
      return null; // Video was not found
    }
  } catch (error) {
    console.error('Error checking if video exists:', error.message); // Log only actual errors
    throw error;
  }
};

export const getVideosByCategory = async (categoryName, filter, limit = 10) => {
  return await fetchWithCache(`/videos//get-videos-by-category-name//${categoryName}`, {
    params: { filter, limit }
  });
};

export const toggleWatchedStatus = async (videoId) => {
  try {
    const response = await authAxios().post(`/videos/toggle-video-watched/${videoId}`);
    return response.data;
  } catch (error) {
    console.error('Error toggling watched status:', error);
    throw error;
  }
};

export const getRelatedVideos = async (videoId) => {
  return fetchWithCache(`/videos/get-related-videos/${videoId}`);
};

export const deleteVideo = async(videoId) => {
  try {
      const response = await axiosInstance.delete(`/videos/delete-video/${videoId}`);
      clearRelatedCache('/videos', 'DELETE'); 
      return response.data;
  } catch (error) {
      console.error('Error deleting video:', error);
      throw error;
  }
};