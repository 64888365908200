export function formatSecondsToHMS(seconds) {
  if (seconds == null || isNaN(seconds)) return null;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  const formattedHours = hours > 0 ? `${hours}:` : "";
  const formattedMinutes = `${hours > 0 ? String(minutes).padStart(2, '0') : minutes}:`;
  const formattedSeconds = String(secs).padStart(2, '0');
  return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
}

export function rawDurationToHMS(duration) {
  if (!duration) return null;
  const match = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
  if (!match) return null;
  const hours = parseInt(match[1] || 0);
  const minutes = parseInt(match[2] || 0);
  const seconds = parseInt(match[3] || 0);
  return formatSecondsToHMS(hours * 3600 + minutes * 60 + seconds);
}




export function formatViewCount(views){
    if (!views || views === 'N/A') return 'N/A';
    return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(views);
  
}