import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import { fetchUserData, toggleWatchedStatus } from '../services/apiService';

const WatchedVideosContext = createContext();

export const useWatchedVideos = () => useContext(WatchedVideosContext);

export const WatchedVideosProvider = ({ children }) => {
  const [watchedVideos, setWatchedVideos] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const getWatchedVideos = async () => {
      if (user) {
        try {
          setIsLoading(true);
          const userData = await fetchUserData();
          setWatchedVideos(new Set(userData.watchedVideos));
        } catch (error) {
          console.error('Error fetching watched videos:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setWatchedVideos(new Set());
        setIsLoading(false);
      }
    };

    getWatchedVideos();
  }, [user]); 

  const toggleWatched = async (videoId) => {
    if (user) {
      try {
        await toggleWatchedStatus(videoId);
        setWatchedVideos(prev => {
          const newSet = new Set(prev);
          if (newSet.has(videoId)) {
            newSet.delete(videoId);
          } else {
            newSet.add(videoId);
          }
          return newSet;
        });
      } catch (error) {
        console.error('Error toggling watched status:', error);
      }
    }
  };

  return (
    <WatchedVideosContext.Provider value={{ watchedVideos, toggleWatched, isLoading }}>
      {children}
    </WatchedVideosContext.Provider>
  );
};