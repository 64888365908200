import { axiosInstance, handleError, clearRelatedCache } from '../apiService';

export const submitUserAction = async (actionData) => {
    try {
        const response = await axiosInstance.post('/user-actions/submit-user-action', actionData);
        return response.data;
    } catch (error) {
        console.error('Error submitting user action:', error);
        throw error;
    }
};

export const getAllUserActions = async () => {
    try {
        const response = await axiosInstance.get('/user-actions/get-all-user-actions');
        return response.data;
    } catch (error) {
        console.error('Error fetching user actions:', error);
        throw error;
    }
};

export const updateUserAction = async (id, status, adminResponse) => {
    try {
        const response = await axiosInstance.put(`/user-actions/update-user-action/${id}`, { status, adminResponse });
        return response.data;
    } catch (error) {
        console.error('Error updating user action:', error);
        throw error;
    }
};