import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { UserContext } from './UserContext';
import { getUserLists, createList, addVideoToList } from '../services/apiService';

export const ListContext = createContext();

export const ListProvider = ({ children }) => {
  const [userLists, setUserLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);

  const fetchUserLists = useCallback(async (forceRefresh = false) => {
    if (user && (loading || forceRefresh)) {
      try {
        setLoading(true);
        const lists = await getUserLists(user._id);
        setUserLists(lists || []);
      } catch (error) {
        console.error('Error fetching user lists:', error);
        setUserLists([]);
      } finally {
        setLoading(false);
      }
    }
  }, [user, loading]);

  useEffect(() => {
    fetchUserLists();
  }, [fetchUserLists]);

  const addList = useCallback(async (listData, videoId = null) => {
    // Create a temporary list for optimistic update
    const tempList = { 
      _id: `temp-${Date.now()}`, 
      ...listData,
      videos: videoId ? [videoId] : []
    };

    // Optimistically update the UI
    setUserLists(prevLists => [...prevLists, tempList]);

    try {
      // Actually create the list
      const createdList = await createList(listData);
      
      // If a videoId was provided, add it to the new list
      if (videoId) {
        await addVideoToList(createdList._id, videoId);
      }

      // Update the list with the real data
      setUserLists(prevLists => 
        prevLists.map(list => 
          list._id === tempList._id ? { ...createdList, videos: videoId ? [videoId] : [] } : list
        )
      );

      return createdList;
    } catch (error) {
      console.error('Error creating new list:', error);
      // Remove the temporary list if creation failed
      setUserLists(prevLists => prevLists.filter(list => list._id !== tempList._id));
      throw error;
    }
  }, []);

  const updateListInContext = useCallback((updatedList) => {
    setUserLists(prevLists => 
      prevLists.map(list => list._id === updatedList._id ? updatedList : list)
    );
  }, []);

  const removeList = useCallback((listId) => {
    setUserLists(prevLists => prevLists.filter(list => list._id !== listId));
  }, []);

  return (
    <ListContext.Provider value={{ 
      userLists, 
      loading,
      addList,
      updateListInContext,
      removeList,
      fetchUserLists
    }}>
      {children}
    </ListContext.Provider>
  );
};

export default ListProvider;