import { axiosInstance, handleError, fetchWithCache, clearRelatedCache } from '../apiService';

export const getCategory = async (categoryName) => {
  try {
    const response = await axiosInstance.get(`/categories/get-category-by-name/${categoryName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category:', error);
    throw error;
  }
};

export const getCategories = async (params = {}) => {
  return await fetchWithCache('/categories/get-categories', {
    params 
  });
};

export const updateCategory = async(categoryId, categoryData) => {
  try {
      const response = await axiosInstance.put(`/categories/update-category/${categoryId}`, categoryData);
      clearRelatedCache('/categories', 'PUT'); 
      return response.data;
  } catch (error) {
      console.error('Error updating category:', error);
      throw error;
  }
};

export const getSubcategories = async (params = {}) => {
  return await fetchWithCache('/categories/get-subcategories', {
    params 
  });
};

export const getSubcategoryByName = async (subcategoryName) => {
  return await fetchWithCache(`/categories/get-subcategory-by-name/${subcategoryName}`);
};

export const createCategory = async(categoryData) => {
  try {
      const response = await axiosInstance.post('/categories/create-category', categoryData);
      clearRelatedCache('/categories', 'POST'); 
      return response.data;
  } catch (error) {
      console.error('Error creating category:', error);
      throw error;
  }
};

export const deleteCategory = async(categoryId) => {
  try {
      const response = await axiosInstance.delete(`/categories/delete-category/${categoryId}`);
      clearRelatedCache('/categories', 'DELETE'); 
      return response.data;
  } catch (error) {
      console.error('Error deleting category:', error);
      throw error;
  }
};

export const createSubcategory = async(subcategoryData) => {
  try {
      const response = await axiosInstance.post('/categories/create-subcategory', subcategoryData);
      clearRelatedCache('/subcategories', 'POST'); 
      return response.data;
  } catch (error) {
      console.error('Error creating subcategory:', error);
      throw error;
  }
};

export const updateSubcategory = async(subcategoryId, subcategoryData) => {
  try {
      const response = await axiosInstance.put(`/categories/update-subcategory/${subcategoryId}`, subcategoryData);
      clearRelatedCache('/subcategories', 'PUT'); 
      return response.data;
  } catch (error) {
      console.error('Error updating subcategory:', error);
      throw error;
  }
};

export const deleteSubcategory = async(subcategoryId) => {
  try {
      const response = await axiosInstance.delete(`/categories/delete-subcategory/${subcategoryId}`);
      clearRelatedCache('/subcategories', 'DELETE'); 
      return response.data;
  } catch (error) {
      console.error('Error deleting subcategory:', error);
      throw error;
  }
};