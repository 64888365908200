// frontend/src/services/api/youtubeApi.js
import { axiosInstance, authAxios, fetchWithCache, clearRelatedCache, cacheService } from '../apiService';

export const validateYoutubeUrl = async (youtubeUrl) => {
  try {
    
    const response = await axiosInstance.post('/youtube/validate-youtube-url', { youtubeUrl });
    return response.data;
  } catch (error) {
    console.error('Error validating YouTube video:', error);
    throw error;
  }
};

export const searchYoutube = async (query, maxResults = 5) => {
  try {
    const response = await axiosInstance.get(`/youtube/search-youtube`, {
      params: { query, maxResults },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching YouTube:', error);
    throw error;
  }
};

export const batchValidateAndCheck = async (videoIds) => {
  try {
    const response = await axiosInstance.post(`/youtube/batch-validate-and-check-youtube-videos`, { videoIds });
    return response.data;
  } catch (error) {
    console.error('Error batch validating and checking videos:', error);
    throw error;
  }
};