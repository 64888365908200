import React, { useState, useEffect, useRef } from 'react';
import Login from './Login';
import Register from './Register';

function RegistrationPrompt({ onClose, isLogin: initialIsLogin }) {
  const [isLogin, setIsLogin] = useState(initialIsLogin);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close the modal if the click is outside of it
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose(); // Close the modal if the Escape key is pressed
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  const handleSuccess = () => {
    onClose(); // Close the modal on success
    window.location.reload(); // Refresh the page or redirect as needed
  };

  return (
    <div className="registration-prompt-overlay">
      <div className="registration-prompt-modal" ref={modalRef}>
        <button onClick={onClose} className="btn btn-close">&times;</button>

        {isLogin ? (
          <Login onSuccess={handleSuccess} />
        ) : (
          <Register onSuccess={handleSuccess} />
        )}

        <div className="toggle-link-container">
          <span onClick={handleToggle} className="toggle-link">
            {isLogin ? 'Create an account' : 'Already have an account? Login'}
          </span>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPrompt;
