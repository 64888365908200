import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import SearchBar from './SearchBar';
import AddVideoModal from './AddVideoModal';
import UserDropdown from './UserDropdown';
import DiscoverDropdown from './DiscoverDropdown';
import { FaUser, FaBars, FaSearch } from 'react-icons/fa';
import RegistrationPrompt from './RegistrationPrompt';
import { FiChevronDown } from 'react-icons/fi';

function Header({ onSearch, onAddVideo }) {
  const { user } = useContext(UserContext);
  const [isAddVideoModalOpen, setIsAddVideoModalOpen] = useState(false);
  const [isRegistrationPromptOpen, setIsRegistrationPromptOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const hamburgerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) &&
        hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
      setShowMobileMenu(false);
    }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let startX;
    const handleTouchStart = (e) => {
      startX = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
      const endX = e.changedTouches[0].clientX;
      const diffX = startX - endX;

      if (diffX > 50 && showMobileMenu) {
        // Swipe left to close
        setShowMobileMenu(false);
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [showMobileMenu]);

  const handleAddVideoClick = (e) => {
    e.stopPropagation();
    setIsAddVideoModalOpen(true);
  };

  const handleCloseAddVideoModal = () => {
    setIsAddVideoModalOpen(false);
  };

  const handleAddVideo = (video) => {
    if (typeof onAddVideo === 'function') {
      onAddVideo(video);
    }
    // handleCloseAddVideoModal();
  };

  const toggleMobileMenu = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the document
    setShowMobileMenu(prevState => !prevState);
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  useEffect(() => {
    // Close mobile menu when navigating to a new page
    closeMobileMenu();
  }, [location]);

  const handleSearchSubmit = (searchTerm) => {
    navigate(`/search${searchTerm ? `?searchTerm=${encodeURIComponent(searchTerm.trim())}` : ''}`);
    setShowMobileMenu(false);
  };

  const handleLoginClick = () => {
    setIsLogin(true);
    setIsRegistrationPromptOpen(true);
  };

  const handleRegisterClick = () => {
    setIsLogin(false);
    setIsRegistrationPromptOpen(true);
  };

  const handleCloseRegistrationPrompt = () => {
    setIsRegistrationPromptOpen(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-left">
          <Link to="/" className="header-title">
            <img src="/logo.png" alt="ytdb-Logo" className="header-logo" />
          </Link>
        </div>
        <div className="header-right">
          <div className="desktop-search">
            <SearchBar onSearch={handleSearchSubmit} />
          </div>
          <div className="mobile-controls">
            <button className="mobile-search-icon" onClick={toggleMobileMenu}>
              <FaSearch />
            </button>
            <button className="hamburger-menu" onClick={toggleMobileMenu}>
              <FaBars />
            </button>
          </div>
          <div ref={mobileMenuRef} className={`mobile-menu ${showMobileMenu ? 'open' : ''}`}>
            <div className="mobile-search">
              <SearchBar onSearch={handleSearchSubmit} />
            </div>
            <Link to="/search" className="nav-link">Browse</Link>
            <DiscoverDropdown />
            <div className="user-controls">
              {user ? (
                <>
                  {user.isAdmin && (
                    <Link to="/admin" className="nav-link">Admin</Link>
                  )}
                  <button onClick={handleAddVideoClick} className="btn btn-secondary">Add Video</button>
                  <div className="user-menu" ref={dropdownRef}>
                    <button onClick={(e) => setShowDropdown(!showDropdown)}>
                      <FaUser />
                      <span className="header-username">{user.username}</span>
                      <FiChevronDown />
                    </button>
                    {showDropdown && <UserDropdown setShowDropdown={setShowDropdown} />}
                  </div>
                </>
              ) : (
                <div>
                  <span onClick={handleLoginClick} className="nav-link">Login</span>
                  {" | "}
                  <span onClick={handleRegisterClick} className="nav-link">Register</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {isAddVideoModalOpen && (
          <AddVideoModal 
            onClose={handleCloseAddVideoModal} 
            onAddVideo={handleAddVideo}
          />
        )}
        {isRegistrationPromptOpen && (
          <RegistrationPrompt 
            onClose={handleCloseRegistrationPrompt}
            isLogin={isLogin}
          />
        )}
      </div>
    </header>
  );
}

export default Header;