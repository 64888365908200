import { axiosInstance } from '../apiService';

export const sendContactForm = async (formData) => {
    try {
      const response = await axiosInstance.post('/contact/send', formData);
      return response.data;
    } catch (error) {
      console.error('Error sending contact form:', error);
      throw error;
    }
  };