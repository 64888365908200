// import React, { useState, useContext, useEffect } from 'react';
// import { UserContext } from '../context/UserContext';
// import { register } from '../services/apiService';


// function Register({ onSuccess }) {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     password: '',
//   });
//   const [error, setError] = useState('');
//   const { setUser } = useContext(UserContext);

//   const { username, email, password } = formData;

//   const onChange = (e) =>
//     setFormData({ ...formData, [e.target.name]: e.target.value });

//   useEffect(() => {
//     // Focus on the username field
//     const usernameInput = document.querySelector('input[name="username"]');
//     if (usernameInput) {
//       usernameInput.focus();
//     }
//   }, []);

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const data = await register(formData);
//       setUser(data.user, data.token);
//       onSuccess();
//     } catch (err) {
//       setError(err.response?.data?.message || 'An error occurred during registration');
//     }
//   };

//   return (
//     <div className="register-container">
//       <form onSubmit={onSubmit}>
//         {error && <p className="error-message">{error}</p>}
//         <input
//           type="text"
//           placeholder="Username"
//           name="username"
//           value={username}
//           onChange={onChange}
//           required
//         />
//         <input
//           type="email"
//           placeholder="Email Address"
//           name="email"
//           value={email}
//           onChange={onChange}
//           required
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           name="password"
//           value={password}
//           onChange={onChange}
//           required
//         />
//         <button type="submit">Register</button>
//       </form>
//     </div>
//   );
// }

// export default Register;


import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { register } from '../services/apiService';
import { validateEmail, validatePassword } from '../utils/validation';

function Register({ onSuccess }) {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const { setUser } = useContext(UserContext);

  const { username, email, password } = formData;

  useEffect(() => {
    // Focus on the username field
    const usernameInput = document.querySelector('input[name="username"]');
    if (usernameInput) {
      usernameInput.focus();
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!username.trim()) {
      newErrors.username = 'Username is required';
    }

    if (!validateEmail(email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!validatePassword(password)) {
      newErrors.password = 'Password must be at least 8 characters long and contain uppercase, lowercase, and numbers';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Clear the error for the field being edited
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const data = await register(formData);
        setUser(data.user, data.token);
        onSuccess();
      } catch (err) {
        setErrors({ 
          ...errors, 
          general: err.response?.data?.message || 'An error occurred during registration' 
        });
      }
    }
  };

  return (
    <div className="register-container">
      <form onSubmit={onSubmit}>
        {errors.general && <p className="error-message">{errors.general}</p>}
        <div className="form-group">
          <input
            type="text"
            placeholder="Username"
            name="username"
            value={username}
            onChange={onChange}
            required
          />
          {errors.username && <p className="error-message">{errors.username}</p>}
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={onChange}
            required
          />
          {errors.password && <p className="error-message">{errors.password}</p>}
        </div>
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default Register;