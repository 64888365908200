// src/services/apiService.js

import axios from 'axios';
import { getToken, setToken, refreshToken, setRefreshToken, removeToken, removeRefreshToken } from '../utils/auth';
import cacheService from './cacheService';

const API_URL = process.env.NODE_ENV === 'production' 
  ? '/api' 
  : 'http://localhost:5181/api';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {

    console.error('Axios error:', error);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        removeToken();
        removeRefreshToken();
        window.location.href = '/';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const loginUser = async (email, password, rememberMe) => {
  try {
    const response = await axiosInstance.post('/auth/login', { email, password, rememberMe });
    const { token, refreshToken, user } = response.data;
    setToken(token);
    setRefreshToken(refreshToken);
    return user;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logoutUser = () => {
  removeToken();
  removeRefreshToken();
  // Redirect to login page or update app state
  window.location.href = '/';
};

// Common error handling
const handleError = (error) => {
  console.error('API call error:', error);
  throw error;
};

// Common response handling
const handleResponse = (response) => response.data;

const authAxios = () => {
  const token = getToken();
  return axios.create({
    baseURL: API_URL,
    headers: token ? { Authorization: `Bearer ${token}` } : {}
  });
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const fetchWithCache = async (url, options = {}, duration = CACHE_DURATION) => {
  const cacheKey = `${url}-${JSON.stringify(options)}`;
  const cachedData = cacheService.get(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  try {
    const response = await axiosInstance(url, options);
    cacheService.set(cacheKey, response.data, duration);
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${url}:`, error);
    throw error;
  }
};

// frontend/src/services/apiService.js
const clearRelatedCache = (url, method = 'GET') => {
  if (url.includes('/videos')) {
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
      cacheService.clear(); // Clear entire cache on video add, update, or delete
    } else if (method === 'GET' && url.includes('/videos//')) {
      // Clear cache for specific video details and potentially related videos
      const videoIdMatch = url.match(/\/videos\/\/([^/?]+)/);
      if (videoIdMatch) {
        cacheService.clear(`/videos//${videoIdMatch[1]}`);
        cacheService.clear(`/videos//${videoIdMatch[1]}/related`);
        cacheService.clear(`/videos//${videoIdMatch[1]}/reviews`); // Clear reviews for this video
      }
    } 
  } else if (url.includes('/lists')) {
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
      cacheService.clear(); // Clear all cache for list changes that could affect many pages
    } else if (method === 'GET' && url.includes('/lists/user/')) {
      const userIdMatch = url.match(/\/lists\/user\/([^/?]+)/);
      if (userIdMatch) {
        cacheService.clear(`/lists/user/${userIdMatch[1]}`); 
      }
    } else if (method === 'GET' && url.includes('/lists/')) {
      // Clear cache for a specific list
      const listIdMatch = url.match(/\/lists\/([^/?]+)/);
      if (listIdMatch) {
        cacheService.clear(`/lists/${listIdMatch[1]}`); 
      }
    }
  } else if (url.includes('/categories') || url.includes('/subcategories') || url.includes('/tags')) {
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
      cacheService.clear(); // Clear cache for category, subcategory, and tag changes
    } else if (method === 'GET') {
      // Clear cache for specific categories, subcategories, or tags
      if (url.includes('/categories/name/')) {
        const categoryNameMatch = url.match(/\/categories\/name\/([^/?]+)/);
        if (categoryNameMatch) {
          cacheService.clear(`/categories/name/${categoryNameMatch[1]}`); 
        }
      // } else if (url.includes('/subcategories/name/')) {
      //   const subcategoryNameMatch = url.match(/\/subcategories\/name\/([^/?]+)/);
      //   if (subcategoryNameMatch) {
      //     cacheService.clear(`/subcategories/name/${subcategoryNameMatch[1]}`); 
      //   }
      } else if (url.includes('/tags/name/')) {
        const tagNameMatch = url.match(/\/tags\/name\/([^/?]+)/);
        if (tagNameMatch) {
          cacheService.clear(`/tags/name/${tagNameMatch[1]}`); 
        }
      } else if (url.includes('/reviews')) { 
        if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
          // If a review is added, updated, or deleted, clear the cache for:
          cacheService.clear(); // Consider clearing the entire cache, or:
          // 1. The specific video's reviews:
          const videoIdMatch = url.match(/\/videos\/([^/?]+)\/reviews/); 
          if (videoIdMatch) {
            cacheService.clear(`/videos//${videoIdMatch[1]}/reviews`); 
          }
          // 2. The user's reviews: 
          if (url.includes('/reviews/user')) {
            cacheService.clear('/reviews/user'); 
          }
        }
      } 
      else {
        // Clear for general lists
        cacheService.clear('/categories/categories');  // For getCategories()
        cacheService.clear('/categories/subcategories'); // For getSubcategories()
        cacheService.clear('/tags'); // For getAllTags()
      }
    }
  } 
  console.info("Cache cleared:",url)
};

const forceRefreshData = (endpoint) => {
  const cacheKey = `${endpoint}-{}`;
  cacheService.forceRefresh(cacheKey);
};

export {
  axiosInstance,
  handleError,
  handleResponse,
  authAxios,
  fetchWithCache,
  clearRelatedCache,
  forceRefreshData,
  cacheService
};

// Export all API functions
export * from './api/adminApi';
export * from './api/categoryApi';
export * from './api/listApi';
export * from './api/reviewApi';
export * from './api/searchApi';
export * from './api/userApi';
export * from './api/videoApi';
export * from './api/tagApi';
export * from './api/userActionApi';
export * from './api/youtubeApi';
export * from './api/suggestApi';
export * from './api/contactApi';