import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { addVideo, getCategories, getSubcategories, suggestMetadata, searchTags, submitReview, checkVideoExists } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import YouTubeSearch from './YoutubeSearch';
import ReviewSubmissionForm from './ReviewSubmissionForm';
import { debounce } from 'lodash';
import { rawDurationToHMS } from '../utils/formatters';

function AddVideoModal({ onClose, onAddVideo }) {
  const { user } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('single');
  const [videoInfo, setVideoInfo] = useState(null);
  const [bulkVideos, setBulkVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [similarityInfo, setSimilarityInfo] = useState(null);
  const [tagInput, setTagInput] = useState('');
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const tagInputRef = useRef(null);
  const [suggestedCategory, setSuggestedCategory] = useState(null);
  // const [suggestedSubcategory, setSuggestedSubcategory] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const modalRef = useRef(null);
  const [isSuggesting, setIsSuggesting] = useState(false);
  const [review, setReview] = useState({ rating: 0, text: '' });
  const [isReviewEnabled, setIsReviewEnabled] = useState(false);
  const [isSuggestedDataUnchanged, setIsSuggestedDataUnchanged] = useState(true);
  const navigate = useNavigate();
  const [initialSuggestedCategory, setInitialSuggestedCategory] = useState(null);
  // const [initialSuggestedSubcategory, setInitialSuggestedSubcategory] = useState(null);
  const [quickAddedVideo, setQuickAddedVideo] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const [tagInputRefs, setTagInputRefs] = useState({});

  const toggleTab = () => {
    setActiveTab(prevTab => prevTab === 'single' ? 'bulk' : 'single');
    setSelectedVideos([]);
    setBulkVideos([]);
    setVideoInfo(null);
  };
  useEffect(() => {
    const handleWheel = (e) => {
      if (modalRef.current && modalRef.current.contains(e.target)) {
        e.stopPropagation();
      }
    };
  
    modalRef.current.addEventListener('wheel', handleWheel, { passive: false });
  
    return () => {
      if (modalRef.current) {
        modalRef.current.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  useEffect(() => {
    if (suggestedCategory && !initialSuggestedCategory) {
      setInitialSuggestedCategory(suggestedCategory);
    }
    // if (suggestedSubcategory && !initialSuggestedSubcategory) {
    //   setInitialSuggestedSubcategory(suggestedSubcategory);
    // }
  // }, [suggestedCategory, suggestedSubcategory]);
}, [suggestedCategory]);

  useEffect(() => {
    fetchCategories();
    // fetchSubcategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await getCategories();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories');
    }
  };

  // const fetchSubcategories = async () => {
  //   try {
  //     const fetchedSubcategories = await getSubcategories();
  //     setSubcategories(fetchedSubcategories);
  //   } catch (error) {
  //     console.error('Error fetching subcategories:', error);
  //     setError('Failed to load subcategories');
  //   }
  // };

  const removeSelectedVideo = (videoId) => {
    if (activeTab === 'bulk') {
      setBulkVideos(prevVideos => prevVideos.filter(v => v.id !== videoId));
      setSelectedVideos(prevSelected => prevSelected.filter(id => id !== videoId));
    } else {
      setVideoInfo(null);
      setSelectedTags([]);
      setSuggestedCategory(null);
      // setSuggestedSubcategory(null);
      setSimilarityInfo(null);
    }
  };

  const fetchTagSuggestions = useCallback(
    debounce(async (input) => {
      if (input.length > 0) {
        try {
          const suggestions = await searchTags(input);
          setTagSuggestions(suggestions);
        } catch (error) {
          console.error('Error fetching tag suggestions:', error);
        }
      } else {
        setTagSuggestions([]);
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchTagSuggestions(tagInput);
  }, [tagInput, fetchTagSuggestions]);

  const handleTagInputChange = (e, index) => {
    const newValue = e.target.value;
    if (activeTab === 'single') {
      setTagInput(newValue);
    } else {
      const newBulkVideos = [...bulkVideos];
      newBulkVideos[index] = { ...newBulkVideos[index], tagInput: newValue };
      setBulkVideos(newBulkVideos);
    }
    setActiveInputIndex(index);
  };

  const handleTagInputFocus = (index) => {
    setActiveInputIndex(index);
  };

  const handleTagInputBlur = () => {
    // Use a longer timeout to delay clearing the active input index
    // This allows more time for tag selection before clearing suggestions
    setTimeout(() => setActiveInputIndex(null), 300);
  };

  useEffect(() => {
    if (activeInputIndex !== null) {
      const currentInput = activeTab === 'single' ? tagInput : (bulkVideos[activeInputIndex]?.tagInput || '');
      fetchTagSuggestions(currentInput);
    }
  }, [activeInputIndex, tagInput, bulkVideos, activeTab]);

  const handleTagSelect = (tag, index) => {
    if (activeTab === 'single') {
      if (!selectedTags.includes(tag)) {
        setSelectedTags(prevTags => [...prevTags, tag]);
      }
    } else {
      const newBulkVideos = [...bulkVideos];
      if (!newBulkVideos[index].tags) {
        newBulkVideos[index].tags = [];
      }
      if (!newBulkVideos[index].tags.includes(tag)) {
        newBulkVideos[index].tags.push(tag);
      }
      setBulkVideos(newBulkVideos);
    }
    
    if (activeTab === 'single') {
      setTagInput('');
    } else {
      const newBulkVideos = [...bulkVideos];
      newBulkVideos[index].tagInput = '';
      setBulkVideos(newBulkVideos);
    }
    
    setTagSuggestions([]);
    setActiveInputIndex(index);
    
    // Keep focus on the correct input
    if (tagInputRefs[index]) {
      tagInputRefs[index].focus();
    }
  };
  
  const handleTagRemove = (tagToRemove, index) => {
    if (activeTab === 'single') {
      setSelectedTags(prevTags => prevTags.filter(tag => tag !== tagToRemove));
    } else {
      setBulkVideos(prevVideos => {
        const updatedVideos = [...prevVideos];
        updatedVideos[index].tags = updatedVideos[index].tags.filter(tag => tag !== tagToRemove);
        return updatedVideos;
      });
    }
    if (tagInputRefs[index]) {
      tagInputRefs[index].focus();
    }
  };
  
  const handleTagInputKeyDown = (e, index) => {
    if (e.key === 'Enter' && (activeTab === 'single' ? tagInput : bulkVideos[index].tagInput).trim() !== '') {
      e.preventDefault();
      if (activeTab === 'single') {
        handleTagSelect(tagInput.trim());
        setTagInput('');
      } else {
        const newBulkVideos = [...bulkVideos];
        newBulkVideos[index].tags = [...(newBulkVideos[index].tags || []), newBulkVideos[index].tagInput.trim()];
        newBulkVideos[index].tagInput = '';
        setBulkVideos(newBulkVideos);
      }
    } else if (e.key === 'Backspace' && (activeTab === 'single' ? tagInput : bulkVideos[index].tagInput) === '') {
      if (activeTab === 'single' && selectedTags.length > 0) {
        const lastTag = selectedTags[selectedTags.length - 1];
        handleTagRemove(lastTag);
      } else if (activeTab === 'bulk' && bulkVideos[index].tags && bulkVideos[index].tags.length > 0) {
        const lastTag = bulkVideos[index].tags[bulkVideos[index].tags.length - 1];
        handleTagRemove(lastTag, index);
      }
    }
  };

  const handleVideoSelect = async (video) => {
    setIsDirty(true);
    setIsSuggesting(true);
  
    try {
      if (activeTab === 'bulk') {
        if (selectedVideos.includes(video.id || video.videoId)) {
          setError('This video is already in the selection list.');
          setIsSuggesting(false);
          return;
        }
        setSelectedVideos(prev => [...prev, video.id || video.videoId]);
      }

      const existingVideo = await checkVideoExists(video.id || video.videoId);
      if (existingVideo) {
        setError(`This video already exists on YTDB. <a href="/video/${existingVideo._id}">View video</a>`);
        video.exists = true;
      } else {
        video.exists = false;
      }
  
      // const { suggestedTags, suggestedCategory, suggestedSubcategory, highestSimilarity, mostSimilarVideoTitle } = await suggestMetadata({
      const { suggestedTags, suggestedCategory, highestSimilarity, mostSimilarVideoTitle } = await suggestMetadata({
        title: video.title,
        description: video.description,
        tags: video.youtubeTags,
        category: video.youtubeCategoryName,
        // subcategory: video.subcategory,
      });
  
      if (activeTab === 'single') {
        setVideoInfo(video);
        if (suggestedTags.length > 0) {
          setSelectedTags(suggestedTags);
        }
        if (suggestedCategory) {
          setSuggestedCategory(suggestedCategory);
          setVideoInfo(prevVideo => ({
            ...prevVideo,
            category: suggestedCategory._id
          }));
        }
        // if (suggestedSubcategory) {
        //   setSuggestedSubcategory(suggestedSubcategory);
        //   setVideoInfo(prevVideo => ({
        //     ...prevVideo,
        //     subcategory: suggestedSubcategory._id
        //   }));
        // }
        setSimilarityInfo({ highestSimilarity, mostSimilarVideoTitle });
        setIsSuggestedDataUnchanged(true);
      } else if (activeTab === 'bulk') {
        const newVideo = {
          ...video,
          category: suggestedCategory ? suggestedCategory._id : '',
          // subcategory: suggestedSubcategory ? suggestedSubcategory._id : '',
          tags: suggestedTags,
          suggestedCategory,
          // suggestedSubcategory,
        };
        setBulkVideos(prevVideos => [...prevVideos, newVideo]);
        setSelectedVideos(prev => [...prev, video.id || video.videoId]);
      }
    } catch (error) {
      console.error('Error getting metadata suggestions:', error);
    } finally {
      setIsSuggesting(false);
    }
  };
  
  const handleQuickAdd = async (video) => {
    setIsSuggesting(true);
    setQuickAddedVideo(null);

    try {
      // Check if the video already exists in the database
      const existingVideo = await checkVideoExists(video.id || video.videoId);
      if (existingVideo) {
        setError('This video is already on YTDB.');
        setIsSuggesting(false);
        return;
      }
      
      // const { suggestedCategory, suggestedSubcategory, suggestedTags } = await suggestMetadata({
      const { suggestedCategory, suggestedTags } = await suggestMetadata({
        title: video.title,
        description: video.description,
        tags: video.youtubeTags,
        category: video.youtubeCategoryName,
        // subcategory: video.subcategory,
      });

      if (suggestedCategory) {
        const newVideo = await addVideo({
          ...video,
          category: suggestedCategory._id,
          // subcategory: suggestedSubcategory ? suggestedSubcategory._id : null,
          tags: suggestedTags || [],
          videoId: video.id || video.videoId,
        });
        onAddVideo(newVideo);
        setQuickAddedVideo({
          ...newVideo,
          _id: newVideo._id, // Ensure _id is included
          categoryName: suggestedCategory.name,
          // subcategoryName: suggestedSubcategory ? suggestedSubcategory.name : 'None',
        });
      } else {
        // If no category suggestion, proceed with normal selection
        handleVideoSelect(video);
      }
    } catch (error) {
      console.error('Error in QuickAdd:', error);
      setError('QuickAdd failed. Please try manual selection.');
    } finally {
      setIsSuggesting(false);
    }
  };

  const handleAddAnother = () => {
    setQuickAddedVideo(null);
  };

  const handleAddVideo = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
  
    try {
      if (activeTab === 'single') {
        if (!videoInfo) {
          throw new Error('No video selected');
        }
        if (isSuggestedDataUnchanged) {
          const shouldProceed = window.confirm("You are using the suggested categories without changes. Are you sure you want to proceed?");
          if (!shouldProceed) {
            setIsLoading(false);
            return;
          }
        }
        const newVideo = await addVideo({
          ...videoInfo,
          category: videoInfo.category,
          // subcategory: videoInfo.subcategory || null,
          tags: selectedTags,
          videoId: videoInfo.videoId,
        });
        // If review is enabled and a rating is provided, submit the review
        if (isReviewEnabled && review.rating > 0) {
          // console.log("Submitting review:", review);
          try {
            const result = await submitReview(newVideo._id, review.rating, review.text);
          } catch (reviewError) {
            console.error('Error adding review:', reviewError);
          }
        } else {
          // console.log("Review data is missing or review is not enabled:", review);
        }
  
        onAddVideo(newVideo);
        setIsDirty(false);
        onClose();
        navigate(`/video/${newVideo._id}`);
      } else {
        if (bulkVideos.length === 0) {
          throw new Error('No videos selected');
        }
        const addedVideos = await Promise.all(bulkVideos.map(video => 
          addVideo({
            ...video,
            category: video.category,
            // subcategory: video.subcategory || null,
            tags: video.tags,
            videoId: video.videoId,
          })
        ));

        addedVideos.forEach(video => onAddVideo(video));
        setIsDirty(false);
        onClose();
      }
    } catch (err) {
      console.error('Error adding video(s):', err);
      setError(err.message || 'Error adding video(s)');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleCloseAttempt = () => {
    if (isDirty && !quickAddedVideo) {
      const confirmClose = window.confirm("You have unsaved changes. Are you sure you want to close?");
      if (confirmClose) {
        onClose();
      }
    } else {
      onClose();
    }
  };
  
  const handleRatingChange = (newRating) => {
    setReview(prevReview => ({ ...prevReview, rating: newRating }));
  };
  
  const handleReviewTextChange = (newText) => {
    setReview(prevReview => ({ ...prevReview, text: newText }));
  };

  const handleInputChange = (e, index) => {
    setIsDirty(true);
    setIsSuggestedDataUnchanged(false);
    const { name, value } = e.target;
    if (activeTab === 'single') {
      setVideoInfo(prev => ({ ...prev, [name]: value }));
      if (name === 'category') {
        setSuggestedCategory(null);
      // } else if (name === 'subcategory') {
      //   setSuggestedSubcategory(null);
      }
    } else {
      const updatedVideos = [...bulkVideos];
      updatedVideos[index] = { ...updatedVideos[index], [name]: value };
      setBulkVideos(updatedVideos);
    }
  };

  const renderCategorySelection = (video, index) => (
    <div className="category-selection">
      <select
        name="category"
        value={video.category || ''}
        onChange={(e) => handleInputChange(e, index)}
        required
      >
        <option value="">Select Category</option>
        {categories.map((cat) => (
          <option key={cat._id} value={cat._id}>{cat.name}</option>
        ))}
      </select>
      <p className="suggestion">
        Suggested: {activeTab === 'single' 
          ? (suggestedCategory ? suggestedCategory.name : 'No Match')
          : (video.suggestedCategory ? video.suggestedCategory.name : 'No Match')}
      </p>
    </div>
  );
  
  // const renderSubcategorySelection = (video, index) => (
  //   <div className="subcategory-selection">
  //     <select
  //       name="subcategory"
  //       value={video.subcategory || ''}
  //       onChange={(e) => handleInputChange(e, index)}
  //     >
  //       <option value="">Select Subcategory</option>
  //       {subcategories.map((subcat) => (
  //         <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
  //       ))}
  //     </select>
  //     <p className="suggestion">
  //       Suggested: {activeTab === 'single'
  //         ? (suggestedSubcategory ? suggestedSubcategory.name : 'No Match')
  //         : (video.suggestedSubcategory ? video.suggestedSubcategory.name : 'No Match')}
  //     </p>
  //   </div>
  // );

  const renderBulkVideoHeaders = () => (
    <div className="bulk-video-headers">
      <div>Title</div>
      <div>Category</div>
      {/* <div>Subcategory</div> */}
      <div>Tags</div>
      <div></div> {/* Empty header for the Remove button column */}
    </div>
  );

  const renderVideoForm = (video, index) => (
    <div key={index} className="video-form">
      <h4 className = "video-form-title">{video.title}</h4>
      {renderCategorySelection(video, index)}
      {/* {renderSubcategorySelection(video, index)} */}
      
      <div className="tag-filter">
        <div className="tag-input-container">
          <div className="tag-pills">
            {(activeTab === 'single' ? selectedTags : video.tags || []).map((tag) => (
              <span key={tag} className="tag-pill">
                {tag}
                <button onClick={() => handleTagRemove(tag, index)}>×</button>
              </span>
            ))}
          </div>
          <input
            ref={el => {
              if (el && !tagInputRefs[index]) {
                setTagInputRefs(prev => ({ ...prev, [index]: el }));
              }
            }}
            type="text"
            placeholder={(activeTab === 'single' ? selectedTags : video.tags || []).length === 0 ? "Enter tags (press Enter to add)" : ""}
            value={activeTab === 'single' ? tagInput : (video.tagInput || '')}
            onChange={(e) => handleTagInputChange(e, index)}
            onKeyDown={(e) => handleTagInputKeyDown(e, index)}
            onFocus={() => handleTagInputFocus(index)}
            onBlur={handleTagInputBlur}
          />
        </div>
        {tagSuggestions.length > 0 && activeInputIndex === index && (
          <ul className="tag-suggestions">
          <li className="suggestion-header">Suggested tags:</li>
          {tagSuggestions.map((tag) => (
            <li key={tag._id} onClick={() => handleTagSelect(tag.name, index)}>
              {tag.name}
            </li>
          ))}
        </ul>
        )}
        {tagInput && tagSuggestions.length === 0 && (
          <p className="no-suggestions">No matching tags found. Press Enter to add as a new tag.</p>
        )}
        
      </div>
      
      <button className = "video-remove-button" onClick={() => removeSelectedVideo(video.id || video.videoId)}>Remove</button>
      
      {similarityInfo && index === 0 && (
        <div className="similarity-info">
          <p><strong>Most Similar Video: ({similarityInfo.highestSimilarity.toFixed(2)}): </strong>{similarityInfo.mostSimilarVideoTitle || 'None'}</p>
        </div>
      )}
      {activeTab === 'single' && (
        <div className="review-toggle">
          <label>
            <input
              type="checkbox"
              checked={isReviewEnabled}
              onChange={(e) => setIsReviewEnabled(e.target.checked)}
            />
            Add a Review
          </label>
        </div>
      )}
      {isReviewEnabled && (
       <ReviewSubmissionForm
       onRatingChange={handleRatingChange}
       onReviewTextChange={handleReviewTextChange}
       onSubmit={(rating, text) => {
         setReview({ rating, text });
         // Note: The actual submission will happen in handleAddVideo
       }}
       error={error}
       showSubmitButton={false} // This hides the submit button in the AddVideoModal context
     />
      )}
    </div>
  );

  return (
    <div className="modal-overlay" onClick={handleCloseAttempt}>
      <div 
        className="modal-content" 
        onClick={(e) => e.stopPropagation()}
        ref={modalRef}
      >
        <div className="modal-header">
          <h2>Add Video</h2>
          <button onClick={handleCloseAttempt} className="close-button">X</button>
        </div>
        
        {quickAddedVideo ? (
          <div className="quick-add-feedback">
            <h3>Video Added Successfully!</h3>
            <p>Title: {quickAddedVideo.title}</p>
            <p>Category: {quickAddedVideo.category}</p>
            {/* <p>Subcategory: {quickAddedVideo.subcategory}</p> */}
            <p>Tags: {quickAddedVideo.tags.join(', ')}</p>
            <a href={`/video/${quickAddedVideo._id}`}>View Video Page</a>
            <button onClick={handleAddAnother}>Add Another Video</button>
          </div>
        ) : (
          <>
            <button className="toggle-video-add-button" onClick={toggleTab}>
              {activeTab === 'single' ? 'Add Multiple Videos' : 'Add Single Video'}
            </button>

            <YouTubeSearch 
                onVideoSelect={handleVideoSelect} 
                onQuickAdd={handleQuickAdd} 
                activeTab={activeTab} 
                selectedVideos={activeTab === 'single' ? [videoInfo?.id || videoInfo?.videoId].filter(Boolean) : selectedVideos}
              />
            {isSuggesting && <p className="suggesting-indicator">Suggesting categories...</p>}

        {isSuggesting && <p className="suggesting-indicator">Suggesting categories...</p>}

        <form onSubmit={handleAddVideo}>
          {activeTab === 'single' ? (
            videoInfo && renderVideoForm(videoInfo, 0)
          ) : (
            <div className="bulk-add-form">
              <h3>Videos to Add</h3>
              {renderBulkVideoHeaders()}
              {bulkVideos.map((video, index) => renderVideoForm(video, index))}
            </div>
          )}

          {(activeTab === 'single' && videoInfo) || (activeTab === 'bulk' && bulkVideos.length > 0) ? (
            <button type="submit" disabled={isLoading || isSuggesting}>
              {isLoading ? 'Adding...' : isSuggesting ? 'Waiting for suggestions...' : `Add Video${activeTab === 'bulk' ? 's' : ''}${isSuggestedDataUnchanged ? ' (with suggestions)' : ''}`}
            </button>
          ) : null}
        </form>
        </>
        )}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default AddVideoModal;