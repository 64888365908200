import { axiosInstance } from '../apiService';
import { setToken, getRefreshToken, setRefreshToken, clearRelatedCache } from '../../utils/auth';

export const fetchUserData = async () => {
  try {
    const response = await axiosInstance.get('/auth/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const updateUserInfo = async (userData) => {
  try {
    const response = await axiosInstance.put('/auth/user', userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user info:', error);
    throw error;
  }
};
export const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await axiosInstance.put('/auth/change-password', { currentPassword, newPassword });
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};
  
export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post(`/auth/login`, { email, password });
    setToken(response.data.token);
    setRefreshToken(response.data.refreshToken);
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const register = async (userData) => {
  try {
    const response = await axiosInstance.post('/auth/register', userData);
    setToken(response.data.token);
    setRefreshToken(response.data.refreshToken);
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const refreshAuthToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }
  try {
    const response = await axiosInstance.post(`/auth/refresh-token`, { refreshToken });
    setToken(response.data.token);
    return response.data.token;
  } catch (error) {
    console.error('Error refreshing auth token:', error);
    throw error;
  }
};