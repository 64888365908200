import { isEmail } from 'validator';
import DOMPurify from 'dompurify';

export const validateEmail = (email) => {
  return isEmail(email);
};

export const validatePassword = (password) => {
  const minLength = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);

  const isValid = minLength && hasUppercase && hasLowercase && hasNumber;

  if (!isValid) {
    let errorMessage = 'Password must:';
    if (!minLength) errorMessage += ' be at least 8 characters long,';
    if (!hasUppercase) errorMessage += ' contain an uppercase letter,';
    if (!hasLowercase) errorMessage += ' contain a lowercase letter,';
    if (!hasNumber) errorMessage += ' contain a number,';
    errorMessage = errorMessage.slice(0, -1) + '.';
    return errorMessage;
  }

  return true;
};

export const sanitizeHtml = (content) => {
  return DOMPurify.sanitize(content);
};