// src/context/DropdownContext.js
import React, { createContext, useState } from 'react';

export const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
    const [openDropdownId, setOpenDropdownId] = useState(null);

    const closeAllDropdowns = () => {
        setOpenDropdownId(null);
    };

    const openDropdown = (id) => {
        setOpenDropdownId(id);
    };

    return (
        <DropdownContext.Provider value={{ openDropdownId, closeAllDropdowns, openDropdown }}>
            {children}
        </DropdownContext.Provider>
    );
};