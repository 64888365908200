import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { login } from '../services/apiService';

function Login({ onSuccess }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [error, setError] = useState('');
  const { setUser } = useContext(UserContext);
  const { email, password, rememberMe } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    // Focus on the username field
    const email = document.querySelector('input[name="email"]');
    if (email) {
      email.focus();
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await login(email, password);
      
      // Ensure response contains the necessary data before proceeding
      if (data && data.user && data.token && data.refreshToken) {
        setUser(data.user, data.token);
        setError('');
        onSuccess();
      } else {
        setError('Invalid response from server');
      }
    } catch (err) {
      console.error(err);
      setError(err.message || 'An error occurred during login');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={onSubmit}>
        {error && <p className="error-message">{error}</p>}
        <input
          type="email"
          placeholder="Email Address"
          name="email"
          value={email}
          onChange={onChange}
          required
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={password}
          onChange={onChange}
          required
        />
        <div className="remember-me">
          <input
            type="checkbox"
            id="rememberMe"
            name="rememberMe"
            checked={rememberMe}
            onChange={(e) => setFormData({ ...formData, rememberMe: e.target.checked })}
          />
          <label htmlFor="rememberMe">Remember Me</label>
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;