import { axiosInstance, handleError, fetchWithCache, clearRelatedCache } from '../apiService';

export const createList = async (listData) => {
  try {
    const response = await axiosInstance.post('/lists/create-list', listData);
    clearRelatedCache('/lists', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error creating list:', error);
    throw error;
  }
};

export const getList = async (listId) => {
  try {
    // Try to fetch the list as a public list first
    const publicResponse = await axiosInstance.get(`/lists/get-public-list/${listId}`);
    return publicResponse.data;
  } catch (publicError) {
    if (publicError.response && publicError.response.status === 404) {
      // If not found as a public list, try to fetch as an authenticated user
      try {
        const response = await axiosInstance.get(`/lists/get-list/${listId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching list:', error);
        throw error;
      }
    } else {
      console.error('Error fetching public list:', publicError);
      throw publicError;
    }
  }
};

export const updateList = async (listId, listData) => {
  try {
    if (!listId) {
      throw new Error('List ID is required');
    }
    const response = await axiosInstance.put(`/lists/update-list/${listId}`, listData);
    clearRelatedCache('/lists', 'PUT'); 
    return response.data;
  } catch (error) {
    console.error('Error updating list:', error);
    throw error;
  }
};

export const deleteList = async (listId) => {
  try {
    const response = await axiosInstance.delete(`/lists/delete-list/${listId}`);
    clearRelatedCache('/lists', 'DELETE'); 
    return response.data;
  } catch (error) {
    console.error('Error deleting list:', error);
    throw error;
  }
};

export const getAllLists = async() => {
  try {
      const response = await axiosInstance.get('/lists/get-all-public-lists');
      return response.data;
  } catch (error) {
      console.error('Error fetching admin lists:', error);
      throw error;
  }
};
  
export const getUserLists = async (userId) => {
  return fetchWithCache(`/lists/get-user-lists/${userId}`);
};

export const addVideoToList = async (listId, videoId) => {
  try {
    const response = await axiosInstance.post(`/lists/add-video-to-list/${listId}`, { videoId });
    clearRelatedCache('/lists');
    return response.data;
  } catch (error) {
    console.error('Error adding video to list:', error);
    throw error;
  }
};

export const removeVideoFromList = async (listId, videoId) => {
  try {
    const response = await axiosInstance.delete(`/lists/remove-video-from-list/${listId}/${videoId}`);
    clearRelatedCache('/lists', 'DELETE'); 
    return response.data;
  } catch (error) {
    console.error('Error removing video from list:', error);
    throw error;
  }
};

export const getPublicList = async (listId) => {
  try {
      const response = await axiosInstance.get(`/lists/get-public-list/${listId}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching public list:', error);
      throw error;
  }
};

export const reorderList = async (listId, direction) => {
  try {
    const response = await axiosInstance.put(`/lists/reorder-list/${listId}`, { direction });
    clearRelatedCache('/lists/user', 'PUT'); 
    return response.data;
  } catch (error) {
    console.error('Error reordering list:', error);
    throw new Error(error.response?.data?.message || 'Failed to reorder list');
  }
};

export const likeList = async (listId) => {
  try {
    const response = await axiosInstance.post(`/lists/like-list/${listId}`);
    clearRelatedCache('/lists', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error liking list:', error);
    throw error;
  }
};

export const unlikeList = async (listId) => {
  try {
    const response = await axiosInstance.delete(`/lists/unlike-list/${listId}`);
    clearRelatedCache('/lists', 'DELETE'); 
    return response.data;
  } catch (error) {
    console.error('Error unliking list:', error);
    throw error;
  }
};

export const copyList = async (listId) => {
  try {
    const response = await axiosInstance.post(`/lists/copy-list/${listId}`);
    clearRelatedCache('/lists', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error copying list:', error);
    throw error;
  }
};

export const saveList = async (listId) => {
  try {
    const response = await axiosInstance.post(`/lists/save-list/${listId}`);
    clearRelatedCache('/lists', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error saving list:', error);
    throw error;
  }
};

export const removeSavedList = async (listId) => {
  try {
    const response = await axiosInstance.delete(`/lists/unsave-list/${listId}`);
    clearRelatedCache('/lists', 'DELETE'); 
    return response.data;
  } catch (error) {
    console.error('Error unsaving list:', error);
    throw error;
  }
};

export const getSavedLists = async () => {
  try {
    const response = await axiosInstance.get('/lists/get-saved-lists');
    return response.data;
  } catch (error) {
    console.error('Error fetching saved lists:', error);
    return []; // Return an empty array if there's an error
  }
};

export const getLikedLists = async () => {
  try {
    const response = await axiosInstance.get('/lists/get-liked-lists');
    return response.data;
  } catch (error) {
    console.error('Error fetching liked lists:', error);
    return []; // Return an empty array if there's an error
  }
};