import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>YTDB</h4>
          <p>Your go-to platform for discovering, rating, and reviewing YouTube videos beyond the algorithm.</p>
          <p>.........</p>
          <p>&copy; 2024 YTDB. All Rights Reserved.</p>
        </div>
        <div className="footer-section">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/categories">Categories</Link></li>
            <li><Link to="/lists">User Lists</Link></li>
            <li><Link to="/tags">Tags</Link></li>
          </ul>
            </div>
            <div className = "footer-section">
          <ul>
          <li><Link to="/search?primarySortBy=weightedRating&primarySortOrder=desc">Advanced Search</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
