//frontend\src\services\api\searchApi.js

import { axiosInstance, handleError, clearRelatedCache } from '../apiService';

export const searchVideos = async (params) => {
  try {
    const searchParams = {...params, includeReviews: true};
    
    if (searchParams.excludeWatched !== 'true') {
      delete searchParams.excludeWatched;
    }

    const validSortOptions = ['weightedRating', 'createdAt', 'publishedAt', 'viewCount', 'durationSeconds', 'trendingScore', 'popularityScore'];
    
    ['primary', 'secondary'].forEach(prefix => {
      const sortBy = `${prefix}SortBy`;
      const sortOrder = `${prefix}SortOrder`;
      
      if (searchParams[sortBy] && validSortOptions.includes(searchParams[sortBy])) {
        searchParams[sortOrder] = ['asc', 'desc'].includes(searchParams[sortOrder]) 
          ? searchParams[sortOrder] 
          : 'desc';
      } else {
        delete searchParams[sortBy];
        delete searchParams[sortOrder];
      }
    });

    delete searchParams.sortBy;
    delete searchParams.sortOrder;

    const response = await axiosInstance.get('/search/search-videos', { params: searchParams });
    return response.data;
  } catch (error) {
    console.error('Error searching videos:', error);
    throw error;
  }
};