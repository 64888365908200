//frontend\src\services\api\tagApi.js

import { axiosInstance, handleError, clearRelatedCache, fetchWithCache } from '../apiService';

export const getAllTags = async ({ primarySortBy, primarySortOrder }) => {
  return await fetchWithCache('/tags//get-all-tags', {
    params: { primarySortBy, primarySortOrder }
  });
};

export const getVideosByTag = async (tagName) => {
  return fetchWithCache(`/tags/get-videos-by-tag/${tagName}`);
};

export const createTag = async (tagData) => {
  try {
    const response = await axiosInstance.post('/tags/create-tag', tagData);
    clearRelatedCache('/tags', 'POST'); 
    return response.data;
  } catch (error) {
    console.error('Error creating tag:', error);
    throw error;
  }
};
  
export const deleteTag = async (tagId) => {
  try {
    const response = await axiosInstance.delete(`/tags/delete-tag/${tagId}`);
    clearRelatedCache('/tags', 'DELETE'); 
    return response.data;
  } catch (error) {
    console.error('Error deleting tag:', error);
    throw error;
  }
};

export const searchTags = async (query) => {
  return await fetchWithCache('/tags//search-tags', {
    params: { query }
  });
};

export const getTagByName = async (tagName) => {
  return await fetchWithCache(`/tags/get-tag-by-name/${encodeURIComponent(tagName)}`);
};